// --- MIXINS ---

// set border
@mixin set-border($position, $height, $style, $color) {
  @if ($position == "top") {
    border-width: #{$height}px 0 0 0; 
    border-style: solid;
    border-color: $color;
  }
  @else if ($position == "right") {
    border-width: 0 #{$height}px 0 0; 
    border-style: solid;
    border-color: $color;
  }
  @else if ($position == "bottom") {
    border-width: 0 0 #{$height}px 0; 
    border-style: solid;
    border-color: $color;
  }
  @else if ($position == "left") {
    border-width: 0 0 0 #{$height}px; 
    border-style: solid;
    border-color: $color;	
  }
  @else if ($position == "right-left-bottom") {
    border-width: 0 #{$height}px #{$height}px #{$height}px; 
    border-style: solid;
    border-color: $color;	
  }
  @else if ($position == "right-bottom") {
    border-width: 0 #{$height}px #{$height}px 0; 
    border-style: solid;
    border-color: $color;	
  }
  @else {
    border-width: #{$height}px #{$height}px #{$height}px #{$height}px; 
    border-style: solid;
    border-color: $color;
  }
}


// set icon size
@mixin setIcon($size: 24, $va: bottom, $mg_right: $app-gap-icon, $mg_left: 0, $color: $app-primary-color-darker) {
  font-size: #{$size}px;
  height: #{$size}px;
  width: #{$size}px;
  color: $color;
  vertical-align: $va;
  margin-right: $mg_right;
  margin-left: #{$mg_left};
}


// set list item view
@mixin setListItemView(
  $height: $app-list-view-facet-item-height, 
  $color: $app-list-view-facet-item-color, 
  $color_hover: $app-primary-color-darker, 
  $color_active: $app-primary-color-darker) {

  height: $height;
  color: $color;

  &:hover {
    color: $color_hover;
  }

  &.app-active {
    color: $color_active;
  }
}


// set state
@mixin setState($color, $percent) {
  background-color: $color;
          
  &:hover {
    background-color: lighten($color, $percent);
  }
  
  .app-count-ex  {
    color: $color;
  }
}


// set special day
@mixin setSpecialDay($bgDay, $bgCircle, $color) {
  background-color: $bgDay;

  .app-calendar-day-content-title,
  .app-calendar-list-item-title {
    color: $bgCircle;
    
    span {
      background-color: $bgCircle;
      color: $color; 
    } 
  }
}


// clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// roboto fontface
@mixin roboto-font($name, $variant, $type, $weight, $style) {

  $font-full-path: '#{$roboto-font-path}/#{$name}-#{$variant}/#{$name}-#{$variant}';

  @font-face {
    font-family: '#{$name}-#{$variant}';
    src: url('#{$font-full-path}-#{$type}.eot');
    src: local('#{$name} #{$variant}'),
         local('#{$name} #{$variant} #{$type}'),
         local('#{$name}-#{$variant} #{$type}'),
         local('#{$name}-#{$variant}-#{$type}'),
         url('#{$font-full-path}-#{$type}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-full-path}-#{$type}.woff2') format('woff2'),
         url('#{$font-full-path}-#{$type}.woff') format('woff'),
         url('#{$font-full-path}-#{$type}.ttf') format('truetype'),
         url('#{$font-full-path}-#{$type}.svg##{$variant}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }

  @font-face {
    font-family: '#{$name}-#{$variant}-#{$type}';
    src: url('#{$name}-#{$font-full-path}-#{$type}.eot');
    src: local('#{$name} #{$variant} #{$type}'),
         local('#{$name}-#{$variant} #{$type}'),
         local('#{$name}-#{$variant}-#{$type}'),
         url('#{$font-full-path}-#{$type}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-full-path}-#{$type}.woff2') format('woff2'),
         url('#{$font-full-path}-#{$type}.woff') format('woff'),
         url('#{$font-full-path}-#{$type}.ttf') format('truetype'),
         url('#{$font-full-path}-#{$type}.svg##{$variant}') format('svg');
  }
}