// --- CALENDAR ---

.app-calendar-wrapper {
  @extend .app-full-wrapper;
  @import "app-calendar-month";
  //@import "app-calendar-list";
  position: relative;

  .mat-flat-button {
    position: fixed;
    right: $app-container;
    bottom: $app-container;

    .mat-icon {
      margin-right: 0;
    }

  }
}
