// --- ADD VDK EX ---

app-add-vdk-ex {
  tbody {
    .mat-row {
      &.error {
        color: $app-red;
      }

      &.app-row-orig {
        background-color: $app-gray-1;
        
        .mat-cell {
          color: $app-gray-7;
          font-size: 0.8rem;
        }
      }
    }
  }
}