// --- PROFILE ---

.app-profile-wrapper {
  height: 100%;
  background-color: $app-gray-1;

  .app-fx {
    padding: $app-gap;
    overflow: auto;
  }
}