// --- RESULT ITEM ---

app-result-item {
  margin-bottom: $app-gap;
  
  .mat-card {
    .mat-card-header {
      min-height: 60px;

      .mat-card-header-text {
        @extend .app-clean-mg;
      }
    }

    &:hover {
     @extend .app-cursor-pointer;
    }
  }
}