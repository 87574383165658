// --- NAVBAR ---

app-navbar {
  .mat-toolbar {
    height: $app-navbar-height;
    background-color: $app-primary-color;

    .app-fx-left {
      @extend .app-font-light;

      .app-logo {
        font-size: 1.8rem;
        position: inherit;
        //margin-right: 20px;
        color: $app-color-white;

        span {
          color: rgba($app-navbar-font-color, .7);
        }
      }

      span {
        &.app-pipe {
          border-color: rgba($app-navbar-font-color, .7) !important;
          font-size: 1.4rem;
          vertical-align: top;
        }

        &.app-route {
          font-size: 1.4rem;
          color: rgba($app-navbar-font-color, .7);
          vertical-align: top;
        }
      }
    }

    

    .app-fx-middle {
      margin-right: $app-gap;

      app-search-bar {
        .app-search-bar-container {
          border-color: $app-color-white;
          max-width: $app-search-bar-max-width;
        }
      }

      @media only screen and (max-width: 1279px) {
        $app-val: 450px;
        flex: 1 1 $app-val;
        min-width: $app-val;
        max-width: $app-val;
      }

      @media only screen and (min-width: 1450px) {
        $app-val: 500px;
        flex: 1 1 $app-val;
        min-width: $app-val;
        max-width: $app-val;
      }

      @media only screen and (min-width: 1600px) {
        $app-val: 640px;
        flex: 1 1 $app-val;
        min-width: $app-val;
        max-width: $app-val;
      }  
    }

    .app-fx-right {
      text-align: right;

      &.app-desktop {
        display: none;

        @media only screen and (min-width: 1280px ) {
          display: block;
        }
      }

      &.app-mobile {
        display: none;

        @media only screen and (max-width: 1279px ) {
          display: block;
        }
      }

      .app-sidenav-btn {
        .mat-icon {
          color: inherit !important; 
        } 
      }

      a {
        color: $app-navbar-font-color;
        padding: 10px;
  
        &.app-user {
          padding: 0 10px;
          margin: 0 10px;
          color: $app-primary-color;
          background-color: rgba($app-color-white, 0.8);
        }
  
        &.active {
          @extend .app-font-bold;
          background-color: $app-primary-color-darker;
          color: $app-color-white;
          border-radius: $app-border-radius;
        }
      }
    }
  }
}

.app-sidenav-container {
  position: absolute !important;
  z-index: 1090 !important;
  background-color: transparent;
  width: 100%;
  // height: calc(100% - 68px);

   &.mat-drawer-container-has-open {
    height: calc(100% - 68px);
  } 

  .mat-drawer-inner-container {
    .mat-list {
      .mat-list-item {
        a {
          color: $app-primary-color !important;
        }

        .mat-button-base {
          background-color: $app-gray-1;
        }
      }
    }
  }
}
