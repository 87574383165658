// --- HOME ---

.app-home-wrapper {
  @extend .app-full-wrapper;
  height: calc(100% - #{$app-navbar-height});
  background-color: $app-gray-1;
  padding: $app-gap;
  padding-top: 50px;

  .app-row-logo {
    justify-content: center;
    text-align: center;

    & > div {
      width: 300px;

      .app-logo {
        @extend .app-font-light;
        font-size: 1.8rem;
        color: $app-primary-color;
      }

      .mat-divider {
        margin: $app-gap 0;
      }

      .app-desc {
        color: $app-gray-5;
      }
    }
  }

  .app-row-search-bar {
    margin: $app-gap 0 calc(2 * #{$app-gap}) 0;

    .app-search-bar-wrapper {
      border-color: $app-gray-3;

      &:hover {
        border-color: $app-gray-2;
      }
    }
  }

  .app-row-items {
    flex-wrap: wrap;
    justify-content: center;

    app-result-item {
      margin-right: 16px !important;

      .mat-button-base:not(.mat-flat-button){
        margin: 0 4px !important;
        padding: 0 9px;
        font-size: 13px;
      }
    }
  }
}
