// --- ADMIN ---

.app-admin-wrapper {
  height: 100%;
  background-color: $app-gray-1;

  //.app-fx-left {
  //  @extend .app-fx-facet;
  //
  //  .mat-list {
  //    padding: $app-gap 0;
  //
  //    .mat-list-item {
  //      @include setListItemView($height: 30px);
  //    }
  //  }
  //}
  //
  //.app-fx-right {
  //  padding: $app-gap;
  //  overflow: auto;
  //}
}
