// --- SEARCH BAR ---

app-search-bar {
  justify-content: center;
  
  .app-search-bar-container {
    background-color: $app-search-bar-bg;
    border-radius: $app-border-radius;
    height: $app-search-bar-height;
    color: $app-search-bar-color;
    max-width: $app-search-bar-max-width;
    display: flex;
    align-items: center;
    border: 1px solid $app-gray-3;

    &:focus-within {
      box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
    }

    .mat-icon {
      margin-left: $app-search-bar-icon-gap;
      margin-right: $app-search-bar-icon-gap;
      vertical-align: middle;
      color: $app-primary-color-darker;

      &.app-icon-clear {
        margin-right: 0 !important;
      }
    }

    input {
      @extend .app-clean-border, .app-clean-pd, .app-clean-bg, .app-font-family-rc;
      width: calc(100% - 90px);
      height: 100%;
      font-size: $app-search-bar-font-size;

      &:focus {
        outline: none;
      }
    }
  }
}