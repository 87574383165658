// --- CALENDAR MONTH ---

.app-calendar-month {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  //height: calc(100% - #{$app-container});

  .app-calendar-weekday {
    width: 14.28%;
    height: 25px;
    margin: 0;
    color: $app-gray-6;
    padding: 0.3rem 0.5rem;
    font-size: 16px;
    @include set-border(right, 1, solid, $app-gray-3);

    &::first-letter {
      text-transform: uppercase;
    }

    &:nth-child(7n) {
      border-right: none !important;
    }
  }

  .app-calendar-day {
    width: 14.28%;
    //height: 16.6666%;
    min-height: 130px;
    margin: 0;
    @include set-border(right-bottom, 1, solid, $app-gray-3);

    &:nth-child(7n) {
      border-right: none !important;
    }

    .app-calendar-day-content {
      color: $app-color-black;
      font-size: 0.9rem;
      height: 100%;
      overflow: hidden;
      padding-bottom: 8px;

      .app-calendar-day-content-title {
        font-size: 1rem;
        color: $app-gray-7;
        padding: 0.3rem 0.5rem;
      }

      &[class*="app-calendar-day-special"] {
        .app-calendar-day-content-title {
          @extend .app-cursor-pointer;

          > span {
            display: block;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
          }
        }
      }

      // set day special
      &.app-calendar-day-special-holiday {@include setSpecialDay($app-day-special-bg, $app-day-holiday-circle, $app-color-white);}
      &.app-calendar-day-special-event {@include setSpecialDay($app-day-special-bg, $app-day-event-circle, $app-color-white);}

      .app-calendar-day-content-item {
        @extend .app-cursor-pointer;
        margin-bottom: 2px;

        & > div:not(.app-btn-show-more) {
          padding: 0.1rem 0.5rem;
        }

        & > div[class*="app-state-"] {
          width: calc(100% - #{$app-container});
          border-radius: $app-border-radius;
          color: $app-color-white;
          margin: 0 auto;
          .app-count-ex {
            border-radius: 50%;
            width: 14px;
            height: 14px;
            line-height: 14px;
            display: inline-block;
            text-align: center;
            background-color: $app-color-white;
            margin-top: 0.15rem;
            margin-left: 0.5rem;
            padding-top: 1px;
          }
        }

        .app-fx-left {
          padding-top: 1px;
        }

        .mat-icon {
          @include setIcon(18);
          margin-left: 2px;
          vertical-align: bottom;
        }

        // set states
        .app-state-ok {@include setState($app-state-ok, 5);}
        .app-state-auto {@include setState($app-state-auto, 7);}

        // show more issues
        .app-btn-show-more {
          a {
            margin-right: $app-container;
            color: $app-gray-9;

            &:hover {
              color: lighten($app-gray-9, 50%);
            }

            span {
              @extend .app-font-bold;
            }
          }
        }
      }


      .app-calendar-day-content-menu {
        .mat-icon {
          color: $app-gray-5;
          margin-top: 0.3rem;
        }
      }

      &.app-disabled {
        .app-calendar-day-content-title {
          color: $app-gray-4;
        }
      }
    }
  }
}
