// --- LAYOUT ---

html,
body,
app-root {
  width: 100%;
  height: 100%;
  display: block;
  @extend .app-clean-mg;
}

body {
  font-family: 'Roboto Condensed', sans-serif !important;
}


app-root {
  main {
    width: 100%;
    height: calc(100% - #{$app-navbar-height});

    app-home {
      display: block;
      width: 100%;
      height: 100%;

      @import "app-home";
      @import "app-result-item";
    }

    app-issue {
      display: block;
      width: 100%;
      height: calc(100% - #{$app-toolbar-height});

      @import "app-issue";
    }

    app-result {
      display: block;
      width: 100%;
      height: calc(100% - #{$app-toolbar-height});

      @import "app-result";
    }

    app-calendar {
      display: block;
      width: 100%;
      height: calc(100% - #{$app-toolbar-height});

      @import "app-calendar";
    }

    app-toolbar {
      @import "app-toolbar";
    }

    app-facet {
      display: block;
      width: $app-facet-col-width;
      height: 100%;
      float: left;

      @import "app-facet";
    }

    app-svazek {
      display: block;
      width: 100%;
      height: 100%;

      @import "app-svazek";
    }

    app-metatitul {
      @import "app-metatitul";
    }

    app-login {
      @import "app-login";
    }

    app-admin {
      @import "app-admin";
    }

    app-admin-users {
      @import "app-admin-users";
    }

    app-admin-owners {
      @import "app-admin-owners";
    }

    app-profile {
      @import "app-profile";
    }
  }
}
