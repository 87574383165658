//--- SVAZEK ---

.app-svazek-wrapper {
  display: flex;
  height: 100%;
  background-color: $app-gray-1;

  .app-container-left {
    flex: 1;
    padding: $app-container;
    //overflow: auto;

    .mat-datepicker-toggle{
      margin-right: 6px;
    }

    tfoot {
      background-color: $app-gray-1;

      //td{
      //  display: flex;
      //  justify-content: flex-start;
      //  align-items: center;
      //}

      .mat-flat-button{
        font-size: 13px;
        line-height: 32px;
        padding: 0 6px;
      }

      .mat-icon-button{
        width: auto;
      }

      .mat-footer-row {
        .mat-footer-cell {
          @extend .app-clean-border;
          padding-left: 5px !important;
          padding-top: 10px;

          .btn {
            //@extend .z-depth-0;
            margin-right: 5px;

            &:disabled {
              background-color: $app-gray-2;
              color: rgba($app-gray-5, .8);
            }
          }
        }
      }
    }
  }

  .app-container-right {
    flex: 2;
    padding: $app-container;
    //padding-left:0;
   // overflow: auto;

    tfoot {
      background-color: $app-color-white;

      .mat-footer-row {
        .mat-footer-cell {
          &:first-child {
            @extend .app-clean-border, .app-clean-pd;

            .app-paginator-wrapper {
              display: flex;
              justify-content: flex-start;
              box-shadow: 0px -4px 36px 4px rgba(0,0,0,0.15);
            }
          }

          &:not(:first-child) {
            display: none;
          }
        }
      }
    }
  }

  .app-table-wrapper {
    &.app-left-top {
      height: $app-svazek-table-left-height;
      overflow: auto;

      .mat-footer-cell {
        padding-bottom: $app-container;

        &:last-child {
          display: none;
        }
      }
    }

    &.app-left-bottom {
      height: calc(100% - #{$app-svazek-table-left-height});
      overflow: auto;

      .mat-footer-cell {
        padding-bottom: 3px;

        &:not(:first-child) {
          display: none;
        }
      }
    }

    &.app-right {
      height: $app-svazek-table-right-height;
      overflow: auto;
    }

    .mat-table {
      width: 100%;

      th, td {
        &:last-child {
          border-right: 0 !important;
        }
      }

      thead {
        .mat-header-row {
          height: 40px;

          .mat-header-cell {
            @extend .app-clean-radius;
            background-color: $app-gray-3;
            vertical-align: bottom;
            border-right: 1px solid white;
            padding-bottom: $app-svazek-header-cell-bottom-space;
            border-bottom: 0 !important;

            &:first-of-type {
              padding-left: 0;
            }

            &:last-of-type {
              padding-right: 0;
            }

            // custom cell width
            &.mat-column-den {
              width: $app-svazek-cell-den-width;
            }

            &.mat-column-datum_vydani {
              min-width: $app-svazek-cell-datum-vydani-width;
              width: $app-svazek-cell-datum-vydani-width;
            }

            &.mat-column-vydani {
              width: $app-svazek-cell-vydani-width;
            }

            &.mat-column-cislo {
              width: $app-svazek-cell-cislo-width;
            }

            &.mat-column-mutace {
              width: $app-svazek-cell-mutace-width;
            }

            &.mat-column-nazev_prilohy {
              width: $app-svazek-cell-mutace-width;
            }


            &:not(.app-cell-rotate) {
              padding-left: 5px;
            }

            &.app-cell-rotate {
              height: $app-svazek-header-row-height;
              white-space: nowrap;

              & > div {
                transform: rotate(-90deg);
                margin-bottom: calc(#{$app-svazek-header-cell-bottom-space} * 2);
                width: 30px !important;
              }
            }


            &.app-cell-rotate {
              width: 30px !important;
            }
          }
        }
      }

      tbody {
        .mat-row {
          .mat-cell {
            @extend .app-clean-radius;
            padding: 0 0 0 5px !important;
            font-size: $app-svazek-cell-font-size;
            height: 32px;

            &.mat-column-vydani {
              min-width: calc(#{$app-svazek-cell-vydani-width} + 8px);
              .mat-form-field {
                width: $app-svazek-cell-vydani-width;
              }
            }

            &.app-edit-text {
              .mat-form-field {
                width: 100%;

                .mat-form-field-wrapper {
                  .mat-form-field-infix {
                    border-top: 0;
                    padding-right: 25px;
                    position: relative;

                    .mat-icon {
                      position: absolute;
                      top: 4px;
                      right: 2px;
                      color: $app-gray-4;
                    }
                  }
                }

                &.app-short-text {
                  .mat-form-field-wrapper {
                    .mat-form-field-infix {
                      padding-right: 0;
                      width: auto;

                      .mat-input-element {
                        padding-right: 0;
                      }
                    }
                  }
                }
              }

              &:focus-within {
                background-color: $app-color-black;
                color: $app-color-white;

                .mat-icon,
                .mat-form-field-label,
                .mat-datepicker-toggle-default-icon {
                  color: $app-color-white !important;
                }
              }
            }

            .mat-checkbox-inner-container {
              width: 15px;
              height: 15px;
            }

            .mat-form-field-wrapper {
              padding-bottom: 0;

              .mat-form-field-infix {
                border-top: 0;
                width: 100%;
              }
            }

            .mat-icon {
              color: $app-primary-color-darker;

              &:before {
                font-size: 18px;
              }
            }

            input {
              font-size: $app-svazek-cell-font-size;
              &#datum_do:disabled, &#datum_od:disabled {
                color: inherit;
              }
              &#datum_do.not_logged, &#datum_od.not_logged {
                color: rgba(0, 0, 0, 0.38);
              }
            }
          }
        }
      }
    }

    tfoot {
      .mat-icon-button {
        color: $app-gray-6;
      }
    }

    .mat-paginator {
      position: sticky;
      bottom: 0;
      left: 0;
      box-shadow: 0px -4px 36px 4px rgba(0,0,0,0.15);

      .mat-paginator-container {
        justify-content: flex-start;
      }
    }
  }

  .check-ok.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: green;
  }

  .app-margin-top-bottom {
    margin: 10px 20px 10px 0;
  }
}
